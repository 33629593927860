<template>
  <div>
    <!-- input search -->
    <div class="d-flex ">
      <b-col
        md="3"
      >
        <b-form-group
          label="Année universitaire"
        >
          <v-select
            v-model="academicYear"
            :clearable="false"
            label="academic_year"
            placeholder="Année universitaire"
            :options="academicYears"
            disabled
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Parcours"
        >
          <v-select
            v-model="parcour"
            :clearable="false"
            placeholder="Parcour"
            label="title"
            :options="parcours"
            @input="getPayments"
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Niveau"
        >
          <v-select
            v-model="level"
            :clearable="false"
            placeholder="Niveau"
            :options="levels"
            @input="getPayments"
          />

        </b-form-group>
      </b-col>

      <!-- <b-col md="3">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </b-col> -->

    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="payments"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <span v-else-if="props.column.field === 'rest_payer'">
          <b-badge variant="warning">
            {{ props.row.rest_payer }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'total_frais'">
          <b-badge variant="success">
            {{ props.row.total_frais }}
          </b-badge>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-info"
              :to="{ name: 'payments-show',
                     params: { id: props.row.student.id} }"
            >    <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EyeIcon"
              class="cursor-pointer ml-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Détail transaction"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormSelect,
  BSpinner, BTooltip, BCol, BBadge,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'
import vSelect from 'vue-select'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    // BFormInput,
    BFormSelect,
    BSpinner,
    BTooltip,
    vSelect,
    BCol,
    BBadge,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      user: {},
      academicYear: {},
      academicYears: [],
      semester: '',
      level: '',
      semesters: ['semester 1', 'semester 2'],
      levels: [1, 2, 3, 4, 5, 6, 7, 8],
      columns: [
        {
          label: 'N°',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'Identité',
          field: 'student.id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Nom et prénom',
          field: 'student.username',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher nom',
          },
        },
        {
          label: 'Email',
          field: 'student.email',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher email',
          },
        },
        {
          label: 'Tel',
          field: 'student.phone',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher phone',
          },
        },
        {
          label: 'Mode Reglément',
          field: 'payment_method',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher phone',
          },
        },
        {
          label: 'Filiére',
          field: 'parcour.sector',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher Filiére',
          },
        },
        {
          label: 'Parcours',
          field: 'parcour.title',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher Parcours',
          },
        },
        {
          label: 'Niveau',
          field: 'student.inscription.level',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher phone',
          },
        },
        {
          label: 'Groupe',
          field: 'student.inscription.group',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher group',
          },
        },
        {
          label: 'Total à payer',
          field: 'total_frais',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Total payé',
          field: 'total_payer',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Total reduction',
          field: 'type_discount',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher phone',
          },
        },
        {
          label: 'Reste à payer',
          field: 'rest_payer',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher phone',
          },
        },

      ],
      payments: [],
      parcours: [],
      parcour: {},
      searchTerm: '',
      currentAcademicYear: {},

    }
  },
  computed: {
    active() {
      const statusColor = 'light-success'

      return status => statusColor[status]
    },

  },
  created() {
    this.getParcours()
    this.getAcademicYears()
    this.getPayments()
    this.user = storeAuth.state.user
    this.currentAcademicYear = storeAuth.state.currentAcademicYear
    this.academicYear = storeAuth.state.currentAcademicYear
  },
  methods: {
    async getAcademicYears() {
      const response = await axios.get('api/academic-years/')
      this.academicYears = response.data
    },
    async getParcours() {
      const response = await axios.get('/api/parcours/')
      this.parcours = response.data
    },
    async getPayments() {
      this.load = 'true'
      const response = await axios.get('/api/payments/', {
        params: {
          academic_year_id: this.currentAcademicYear.id, parcour_id: this.parcour.id,
        },
      })
      this.payments = response.data
      this.load = 'false'
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      // const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )}`
    },
    deleteTeachingUnit(id) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.load = 'true'
        if (result.value) {
          axios
            .delete(`/api/teaching-units/delete/${id}/`)
            .then(() => {
              this.load = 'false'
              this.getPayments()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'unités enseignement supprimée avec succés')
              }, 1000)
            })
            .catch(error => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'unités enseignement Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
